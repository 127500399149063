<birdz-card [isLoading]="isLoading" [isEmpty]="!hasPerimeter">
  <div card-title>
    {{ 'EQUIPMENTS_AND_DEVICES' | transloco }}
    <button class="help" mat-icon-button (click)="help()">
      <mat-icon>info</mat-icon>
    </button>
  </div>
  <div card-subtitle>
    @if (stats?.date) { {{ 'DATE_AT' | transloco }} {{ (stats?.date ?? '') | formatDate }} }
  </div>
  <div card-empty>{{ "PLEASE_SELECT_ONE_SINGLE_CONTRACT" | transloco }}</div>
  <div card-body class="body">
    @if (stats) { @if (stats.date === null) {
    <div class="no-data">{{ 'NO_DATA_AVAILABLE' | transloco }}</div>
    } @else {
    <!-- total -->
    <div class="total">
      {{ 'METERS_TOTAL' | transloco }} {{ 'COLON' | transloco }} {{ stats.pds_total ?? 0 |
      formatNumber }}
      <div class="toggle">
        <mat-slide-toggle (change)="toggleView()" [(ngModel)]="isDetailed"
          >{{ 'DETAILED_VIEW' | transloco }}</mat-slide-toggle
        >
      </div>
    </div>

    <!-- chart -->
    @if (chartOptions) {
    <div class="chart-layout">
      <highcharts-chart
        [Highcharts]="Highcharts"
        [(update)]="updateFlag"
        class="chart"
        (chartInstance)="setChartInstance($event)"
        [options]="chartOptions"
      ></highcharts-chart>
      <div class="legend">
        <app-legend-item
          id="equipped_meters"
          [chart]="chart"
          [update]="updateFlag"
          [color]="cornflower"
          title="{{ 'EQUIPPED_METERS' | transloco }}"
          [subtitle]="subtitleEquipped"
          [children]="['equipped_meters_fix_network', 'equipped_meters_walk_by']"
          [forceDisplay]="true"
        />
        <app-legend-item
          id="equipped_meters_fix_network"
          [chart]="chart"
          [update]="updateFlag"
          [level]="1"
          title="{{ 'EQUIPPED_METERS_FIX_NETWORK' | transloco }}"
          [subtitle]="subtitleEquippedFN"
        />
        <app-legend-item
          id="equipped_meters_walk_by"
          [chart]="chart"
          [update]="updateFlag"
          [level]="1"
          title="{{ 'EQUIPPED_METERS_WALK_BY' | transloco }}"
          [subtitle]="subtitleEquippedWB"
        />
        <app-legend-item
          id="non_equipped_meters"
          [chart]="chart"
          [update]="updateFlag"
          title="{{ 'NON_EQUIPPED_METERS' | transloco }}"
          [subtitle]="subtitleNonEquipped"
        />
      </div>
    </div>
    }
    <!-- main -->
    @if (stats) {
    <div class="figure">
      {{ ((stats.pds_total ??0) !== 0 ? ((stats.pds_equipped ?? 0) / (stats.pds_total ?? 0) * 100) :
      0) | formatNumber: '0.0-1' }}% {{ 'OF_EQUIPPED_METERS' | transloco }}
    </div>
    } }
    <ng-template #main>
      <!-- total -->
      <div class="total">
        {{ 'METERS_TOTAL' | transloco }} {{ 'COLON' | transloco }} {{ stats.pds_total ?? 0 |
        formatNumber }}
      </div>
      <!-- chart -->
      @if (chartOptions) {
      <highcharts-chart
        [Highcharts]="Highcharts"
        [(update)]="updateFlag"
        class="chart"
        [options]="chartOptions"
      ></highcharts-chart>
      }
      <!-- main -->
      @if (stats) {
      <div class="figure">
        {{ ((stats.pds_total ??0) !== 0 ? ((stats.pds_equipped ?? 0) / (stats.pds_total ?? 0) * 100)
        : 0) | formatNumber: '0.0-1' }}% {{ 'OF_EQUIPPED_METERS' | transloco }}
      </div>
      }
    </ng-template>
    }
  </div>
</birdz-card>
