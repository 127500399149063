import { User as BirdzUser, pick } from '@web/birdz-angular';

export class User extends BirdzUser {
  need_consent?: boolean;
  constructor(fields: any) {
    super(fields);

    // set Child class properties (not settable from the super constructor, when tsconfig option useDefineForClassFields is true)
    Object.assign(this, pick(fields, 'need_consent'));
  }
}
