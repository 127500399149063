<birdz-modal>
  <div modal-title>{{ 'HELP_INDEX_1' | transloco }}</div>
  <div modal-body class="body">
    <p>{{ 'HELP_INDEX_2' | transloco }}{{ 'COLON' | transloco }}</p>
    <ul>
      <li>
        {{ 'HELP_INDEX_3' | transloco }}<br />
        <code>{{ 'HELP_INDEX_3B' | transloco }}</code>
      </li>
    </ul>
    <p>{{ 'HELP_INDEX_4' | transloco }}</p>
    <p>{{ 'HELP_INDEX_5' | transloco }}</p>
    <p>{{ 'HELP_INDEX_6' | transloco }}</p>
  </div>
</birdz-modal>
