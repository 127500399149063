<mat-tab-group
  [(selectedIndex)]="selectedTab"
  dynamicHeight="true"
  animationDuration="200ms"
  [mat-stretch-tabs]="false"
  class="birdz-filter-tab-group"
>
  <mat-tab label="{{ 'FILTERS' | transloco | uppercase }}">
    @if (form) {
    <form class="filters" [formGroup]="form">
      @if (isLoading) {
      <div class="loader">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      }
      <mat-accordion [multi]="true">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> {{ 'PERIMETER' | transloco }} </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="perimeter">
            <!-- contracts -->
            @if (showContractFilter) {
            <mat-form-field>
              <mat-label>
                {{ 'CONTRACTS' | transloco }} ({{ form.get('contracts')?.value?.length |
                formatNumber }})
              </mat-label>
              <mat-select
                birdzSelectWithVirtualScroll
                (openedChange)="!$event && updateOptions()"
                (selectionChange)="updateOptions()"
                multiple
                formControlName="contracts"
                panelClass="birdz-wide-select"
                class="birdz-select-trigger-small-font"
                [optionToLabel]="contractOptionToLabel"
              >
                <!-- search bar -->
                <birdz-select-search-bar
                  [options]="options.contracts"
                  #contractSearch
                  (selectedChange)="setContracts($event)"
                >
                </birdz-select-search-bar>
                <!-- filtered options -->
                <cdk-virtual-scroll-viewport>
                  <mat-option
                    *cdkVirtualFor="let contract of (options.contracts | filter: contractSearch.filter)"
                    [value]="contract.id"
                    matTooltip="{{ contract.label }}"
                    matTooltipPosition="after"
                    matTooltipShowDelay="500"
                  >
                    {{ contract.id }} - {{ contract.label }}
                  </mat-option>
                </cdk-virtual-scroll-viewport>
              </mat-select>
            </mat-form-field>
            }
            <!-- geocodes -->
            <mat-form-field>
              <mat-label>
                {{ 'GEOCODES' | transloco }} ({{ form.get('geocodes')?.value?.length | formatNumber
                }})
              </mat-label>
              <mat-select
                birdzSelectWithVirtualScroll
                [optionToLabel]="geocodeOptionToLabel"
                multiple
                panelClass="birdz-wide-select"
                formControlName="geocodes"
              >
                <!-- search bar -->
                <birdz-select-search-bar
                  [options]="options.geocodes"
                  [searchFunction]="searchGeocodeFn"
                  #geocodeSearch
                  (selectedChange)="setGeocodes($event)"
                >
                </birdz-select-search-bar>
                <!-- filtered options -->
                <cdk-virtual-scroll-viewport>
                  <mat-option
                    *cdkVirtualFor="let geocode of (options.geocodes | filter: geocodeSearch.filter )"
                    [value]="geocode.id"
                    matTooltip="{{ geocode.city_name }}"
                    matTooltipPosition="after"
                    matTooltipShowDelay="500"
                  >
                    {{ geocode.city_name }}
                  </mat-option>
                </cdk-virtual-scroll-viewport>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </form>
    }
  </mat-tab>
</mat-tab-group>

<!-- footer -->
<div class="footer">
  @if (form) {
  <form [formGroup]="form">
    <button mat-stroked-button color="primary" (click)="reset()">
      {{ 'RESET_FILTERS' | transloco | uppercase}}
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="form.invalid || isApplied"
      (click)="submit()"
    >
      {{ 'APPLY' | transloco | uppercase}}
    </button>
  </form>
  }
</div>
