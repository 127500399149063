import { Component } from '@angular/core';
import { CardDetailsComponent } from 'app/components/card-details/card-details.component';

@Component({
  selector: 'app-page-detail',
  standalone: true,
  imports: [CardDetailsComponent],
  templateUrl: './page-details.component.html',
  styleUrl: './page-details.component.scss',
})
export class PageDetailComponent {}
