import { AsyncPipe, JsonPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import {
  AuthComponent,
  AuthService,
  BirdzTenantService,
  CardTenantComponent,
  ContactForm,
  DrawerComponent,
  HeaderComponent,
  IfAuthorizedDirective,
  LayoutComponent,
  LoaderService,
  MessengerService,
  ModalGdprComponent,
  Profile,
} from '@web/birdz-angular';
import { FilterComponent } from 'app/components/filter/filter.component';
import {
  Observable,
  catchError,
  combineLatest,
  finalize,
  map,
  skipWhile,
  startWith,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterOutlet,
    MatTabsModule,
    DrawerComponent,
    MatMenuModule,
    TranslocoPipe,
    RouterLink,
    RouterLinkActive,
    FilterComponent,
    LayoutComponent,
    HeaderComponent,
    AsyncPipe,
    AuthComponent,
    CardTenantComponent,
    JsonPipe,
    IfAuthorizedDirective,
  ],
  standalone: true,
})
export class AppComponent implements OnInit {
  profile$: Observable<Profile>;
  isLoading$: Observable<boolean>;
  settings$: Observable<any>;
  logoRedirectionUrl: string = '';
  needsTenant$: Observable<boolean>;
  tabs = [
    {
      label: `TAB_DASHBOARD`,
      path: `dashboard`,
    },
    {
      label: `TAB_DETAILS`,
      path: `details`,
    },
    {
      label: `TAB_MAP`,
      path: `map`,
    },
  ];

  constructor(
    private userService: UserService,
    private loader: LoaderService,
    private auth: AuthService,
    private messenger: MessengerService,
    private title: Title,
    public dialog: MatDialog,
    private transloco: TranslocoService,
    private tenantService: BirdzTenantService
  ) {
    this.profile$ = this.userService.user$.pipe(map((user) => user.toProfile()));
    this.settings$ = this.userService.user$.pipe(map((user: User) => user?.['settings']));
    this.isLoading$ = this.loader.isLoading$;
    this.needsTenant$ = combineLatest([
      this.userService.user$,
      this.tenantService.tenantId$.pipe(startWith(null)),
    ]).pipe(map(([user, tenantId]) => user?.isBirdzAdmin() === true && !tenantId));
  }

  ngOnInit() {
    this.setLogo();
    this.userService.fetchUser().subscribe();
    this.translateTitle();
    this.handleGDPR();
  }

  handleGDPR() {
    this.userService.needConsent$
      .pipe(
        skipWhile((needConsent) => !needConsent),
        take(1)
      )
      .subscribe(() => {
        this.openGDPRModal(true);
      });
  }

  openGDPRModal(requireConsent = false) {
    const config = {
      data: {
        appName$: this.transloco.selectTranslate('APP_NAME'),
        submit$: requireConsent ? this.userService.grantConsent() : null,
      },
    };

    this.dialog
      .open(ModalGdprComponent, config)
      .afterClosed()
      .subscribe(() => {
        if (requireConsent) {
          this.userService.setNeedConsent(false);
        }
      });
  }

  translateTitle() {
    this.transloco.langChanges$
      .pipe(
        switchMap(() => this.transloco.selectTranslate('APP_NAME')),
        tap((message) => this.title.setTitle(message))
      )
      .subscribe();
  }

  logout() {
    this.userService
      .clearCache() // clear cache first because this is an authenticated request
      .pipe(
        finalize(() =>
          this.auth
            .logout() // logout (clear cookie and logout from (keycloak) provider)
            .pipe(finalize(() => this.auth.redirectToLogin())) // redirect to login page
            .subscribe()
        )
      )
      .subscribe();
  }

  onUserSettingsChange(settings: Record<string, any>) {
    this.userService
      .patchSettings(settings)
      .pipe(
        this.messenger.showFriendlyErrorMessage(),
        switchMap(() => this.transloco.selectTranslate('USER_SETTINGS_UPDATED')),
        tap((message) => this.messenger.success(message))
      )
      .subscribe();
  }

  contactCallback = (form: ContactForm) => {
    const body = {
      contact_email: form.email,
      topic: form.topic,
      message: form.message,
      name: form.name,
      phone: form.phone,
      url: window.location.href,
    };
    return this.userService.contactSupport(body).pipe(
      catchError((error) => {
        return throwError(() => error.friendlyMessage);
      })
    );
  };

  setLogo() {
    this.userService.user$.pipe(tap((user) => this.setLogoUrl(user))).subscribe();
  }

  setLogoUrl(user: User | null) {
    const portalApplication = user?.getApplication('portal');
    if (portalApplication) {
      this.logoRedirectionUrl = portalApplication.url;
    }
  }
}
