<birdz-modal>
  <div modal-title>{{ 'HELP_EQUIPPING_1' | transloco }}</div>
  <div modal-body class="body">
    <dl class="dl">
      <dt>{{ 'HELP_EQUIPPING_2' | transloco }}</dt>
      <dd>{{ 'HELP_EQUIPPING_3' | transloco }}</dd>

      <dt>{{ 'HELP_EQUIPPING_2A' | transloco }}</dt>
      <dd>{{ 'HELP_EQUIPPING_3A' | transloco }}</dd>

      <dt>{{ 'HELP_EQUIPPING_2B' | transloco }}</dt>
      <dd>{{ 'HELP_EQUIPPING_3B' | transloco }}</dd>

      <dt>{{ 'HELP_EQUIPPING_4' | transloco }}</dt>
      <dd>{{ 'HELP_EQUIPPING_5' | transloco }}</dd>

      <dt>{{ 'HELP_EQUIPPING_6' | transloco }}</dt>
      <dd>{{ 'HELP_EQUIPPING_7' | transloco }}</dd>
    </dl>
    <p>{{ 'HELP_EQUIPPING_8' | transloco }}</p>
  </div>
</birdz-modal>
