<div class="grid">
  <app-card-equipping
    [isLoading]="isLoadingStats > 0"
    [hasPerimeter]="(hasPerimeter$ | async) === true"
    [stats]="stats?.equipping"
    class="equipping"
  ></app-card-equipping>
  <app-card-remote-reading
    [isLoading]="isLoadingStats > 0"
    [hasPerimeter]="(hasPerimeter$ | async) === true"
    [stats]="stats?.remote_reading"
    class="remote-reading"
  ></app-card-remote-reading>
  <app-card-index-reporting
    (periodChange)="handlePeriodChange($event)"
    [isLoading]="isLoadingIndexStats > 0 || isLoadingKpis > 0"
    [hasPerimeter]="(hasPerimeter$ | async) === true"
    [stats]="indexStats"
    [kpis]="kpis"
    class="index-reporting"
  ></app-card-index-reporting>
</div>
