import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import * as Sentry from '@sentry/angular';
import { SentryHelper, provideBirdz } from '@web/birdz-angular';
import { enableHighchartsNoDataDisplay } from '@web/birdz-angular/highcharts';
import { routes } from 'app/app.routes';
import { AppComponent } from './app/components/app/app.component';
import { environment as env } from './environments/environment';

enableHighchartsNoDataDisplay();

Sentry.init({
  denyUrls: [/^https?:\/\/[a-zA-Z0-9-]*\.ew\.r\.appspot\.com/],
  environment: env.env,
  dsn: env.sentryDSN,
  beforeSend: SentryHelper.beforeSend,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracePropagationTargets: env.sentryTracingOrigins,
  tracesSampleRate: env.sentryTracesSampleRate,
});

if (env.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserAnimationsModule),
    provideRouter(routes),
    provideBirdz({
      env: env.env,
      apiUrl: env.apiUrl,
      redirectToDomain: env.redirectToDomain,
      showEnv: true,
      messages: {
        url: env.messagesUrl,
        cookiesDomain: env.messagesCookiesDomain,
      },
      auth: {
        loginUrl: env.loginUrl,
        loginUrlSilent: env.loginUrlSilent,
        logoutUrl: env.logoutUrl,
        bypassOAuth: env.bypassOAuth,
      },
      errorHandler: Sentry.createErrorHandler({
        showDialog: false,
        extractor: SentryHelper.extractor,
      }),
    }),
    provideTranslocoMessageformat(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => {
  console.error(err); // eslint-disable-line
});
