<birdz-card [isLoading]="isLoading > 0" [isEmpty]="(hasPerimeter$ | async) !== true">
  <div card-title>{{ 'TAB_DETAILS' | transloco }}</div>
  <div card-empty>{{ "PLEASE_SELECT_ONE_SINGLE_CONTRACT" | transloco }}</div>
  <div card-body>
    @if (hasPerimeter$ | async) {
    <birdz-table-wrapper
      [id]="'details'"
      [pagination]="true"
      [dataSource]="dataSource"
      [showResetFilter]="true"
      [(columns)]="columns"
      (resetColumnsClicked)="resetColumns()"
    >
      <table mat-table matSort aria-label="alarmes">
        <ng-container matColumnDef="id">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [matTooltip]="'TTP_PDS' | transloco"
          >
            {{ 'COL_ID' | transloco }}
            <birdz-table-filter-button [filter]="filters.get('id')" />
          </th>
          <td mat-cell *matCellDef="let pds">{{pds.id}}</td>
        </ng-container>
        <ng-container matColumnDef="date_calcul">
          <th mat-header-cell *matHeaderCellDef [matTooltip]="'TTP_DATE_CALCUL' | transloco">
            {{ 'COL_DATE_CALCUL' | transloco }}
          </th>
          <td mat-cell *matCellDef="let pds">{{pds.date_calcul | formatDate}}</td>
        </ng-container>
        <ng-container matColumnDef="compteur">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'COL_COMPTEUR' | transloco }}
            <birdz-table-filter-button [filter]="filters.get('compteur')" />
          </th>
          <td mat-cell *matCellDef="let pds">{{pds.compteur || '-'}}</td>
        </ng-container>
        <ng-container matColumnDef="module">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'COL_MODULE' | transloco }}
            <birdz-table-filter-button [filter]="filters.get('module')" />
          </th>
          <td mat-cell *matCellDef="let pds">{{pds.module || '-'}}</td>
        </ng-container>
        <ng-container matColumnDef="contrat">
          <th mat-header-cell *matHeaderCellDef>{{ 'COL_CONTRAT' | transloco }}</th>
          <td mat-cell *matCellDef="let pds" class="no-wrap">
            {{pds.code_contrat ?? '-' }}&nbsp;-&nbsp;{{ pds.libelle_contrat ?? '-' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="nom_commune">
          <th mat-header-cell *matHeaderCellDef>{{ 'COL_NOM_COMMUNE' | transloco }}</th>
          <td mat-cell *matCellDef="let pds">{{pds.nom_commune || '-'}}</td>
        </ng-container>
        <ng-container matColumnDef="type_de_module">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [matTooltip]="'TTP_TYPE_DE_MODULE' | transloco"
          >
            {{ 'COL_TYPE_DE_MODULE' | transloco }}
            <birdz-table-filter-button [filter]="filters.get('type_de_module')" />
          </th>
          <td mat-cell *matCellDef="let pds">{{pds.type_de_module || '-' }}</td>
        </ng-container>
        <ng-container matColumnDef="version_de_module">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [matTooltip]="'TTP_VERSION_DE_MODULE' | transloco"
          >
            {{ 'COL_VERSION_DE_MODULE' | transloco }}
            <birdz-table-filter-button [filter]="filters.get('version_de_module')" />
          </th>
          <td mat-cell *matCellDef="let pds">{{pds.version_de_module || '-' }}</td>
        </ng-container>
        <ng-container matColumnDef="raccorde">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'COL_RACCORDE' | transloco }}
            <birdz-table-filter-button [filter]="filters.get('raccorde')" />
          </th>
          <td mat-cell *matCellDef="let pds">
            @switch(pds.raccorde) { @case(0) {
            <div [matTooltip]="'OPTION_NOT_CONNECTED' | transloco">❌</div>
            } @case(1) {
            <div [matTooltip]="'OPTION_CONNECTED' | transloco">✅</div>
            } @default { - } }
          </td>
        </ng-container>
        <ng-container matColumnDef="date_derniere_trame_recue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'COL_DATE_DERNIERE_TRAME_RECUE' | transloco }}
            <birdz-table-filter-button [filter]="filters.get('date_derniere_trame_recue')" />
          </th>
          <td mat-cell *matCellDef="let pds">
            {{(pds.date_derniere_trame_recue | formatDate) || '-'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="etat_supervision">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'COL_ETAT_SUPERVISION' | transloco }}
            <birdz-table-help-button>
              <p>{{ '4_POSSIBLE_STATES' | transloco }}</p>
              <ul>
                <li>
                  <div class="round round--pastel-green"></div>
                  {{ 'FUNCTIONAL' | transloco }}
                </li>
                <li>
                  <div class="round round--blue-zodiac"></div>
                  {{ 'PRESUMED_NON_COMMUNICATING' | transloco }}
                </li>
                <li>
                  <div class="round round--cornflower"></div>
                  {{ 'PRESUMED_OUT_OF_SERVICE' | transloco }}
                </li>
                <li>
                  <div class="round round--grey"></div>
                  {{ 'UNKNOWN' | transloco }}
                </li>
              </ul>
            </birdz-table-help-button>
            <birdz-table-filter-button [filter]="filters.get('etat_supervision')" />
          </th>
          <td mat-cell *matCellDef="let pds">
            @switch(pds.etat_supervision) { @case('OK') {
            <div class="round round--pastel-green" [matTooltip]="'FUNCTIONAL'| transloco"></div>
            } @case('NC') {
            <div
              class="round round--blue-zodiac"
              [matTooltip]="'PRESUMED_NON_COMMUNICATING'| transloco"
            ></div>
            } @case('HS') {
            <div
              class="round round--cornflower"
              [matTooltip]="'PRESUMED_OUT_OF_SERVICE'| transloco"
            ></div>
            } @default {
            <div class="round round--grey" [matTooltip]="'UNKNOWN'| transloco"></div>
            } }
          </td>
        </ng-container>
        <ng-container matColumnDef="dernier_index_livre">
          <th mat-header-cell *matHeaderCellDef>{{ 'COL_DERNIER_INDEX_LIVRE' | transloco }}</th>
          <td mat-cell *matCellDef="let pds">
            {{ (pds.dernier_index_livre !== null && pds.dernier_index_livre !== undefined ) ?
            (pds.dernier_index_livre / 1000 | formatNumber: '1.3') + ' m³' : '-' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="dateref_index_livre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'COL_DATEREF_INDEX_LIVRE' | transloco }}
            <birdz-table-filter-button [filter]="filters.get('dateref_index_livre')" />
          </th>
          <td mat-cell *matCellDef="let pds">
            {{ (pds.dateref_index_livre | formatDate) || '-' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="date_livraison">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'COL_DATE_LIVRAISON' | transloco }}
            <birdz-table-filter-button [filter]="filters.get('date_livraison')" />
          </th>
          <td mat-cell *matCellDef="let pds">{{ (pds.date_livraison | formatDate) || '-' }}</td>
        </ng-container>
        <ng-container matColumnDef="ok_1j_4h">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [matTooltip]="'TTP_OK_1J_4H' | transloco"
          >
            {{ 'COL_OK_1J_4H' | transloco }}
            <birdz-table-filter-button [filter]="filters.get('ok_1j_4h')" />
          </th>
          <td mat-cell *matCellDef="let pds">
            @switch(pds.ok_1j_4h) { @case(0) {
            <div>❌</div>
            } @case(1) {
            <div>✅</div>
            } @default { - } }
          </td>
        </ng-container>
        <ng-container matColumnDef="motif_exclu">
          <th mat-header-cell *matHeaderCellDef [matTooltip]="'TTP_MOTIF_EXCLU' | transloco">
            {{ 'COL_MOTIF_EXCLU' | transloco }}
          </th>
          <td mat-cell *matCellDef="let pds">
            {{pds.motif_exclu ? (pds.motif_exclu | join) : '-'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="dernier_index_integre">
          <th mat-header-cell *matHeaderCellDef class="admin-birdz">
            {{ 'COL_DERNIER_INDEX_INTEGRE' | transloco }}
          </th>
          <td mat-cell *matCellDef="let pds">
            {{ (pds.dernier_index_integre !== null && pds.dernier_index_integre !== undefined ) ?
            (pds.dernier_index_integre / 1000 | formatNumber: '1.3') + ' m³' : '-' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="dateref_index_integre">
          <th mat-header-cell *matHeaderCellDef class="admin-birdz">
            {{ 'COL_DATEREF_INDEX_LIVRE' | transloco }}
          </th>
          <td mat-cell *matCellDef="let pds">
            {{ (pds.dateref_index_integre | formatDate) || '-' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="type_service_export">
          <th mat-header-cell *matHeaderCellDef class="admin-birdz">
            {{ 'COL_TYPE_SERVICE_EXPORT' | transloco }}
          </th>
          <td mat-cell *matCellDef="let pds">{{ pds.type_service_export || '-' }}</td>
        </ng-container>
        <ng-container matColumnDef="id_service_export">
          <th mat-header-cell *matHeaderCellDef class="admin-birdz">
            {{ 'COL_ID_SERVICE_EXPORT' | transloco }}
          </th>
          <td mat-cell *matCellDef="let pds">{{ pds.id_service_export || '-' }}</td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columns | filter: isActive | pluck: 'value'; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columns  | filter: isActive | pluck: 'value';"
        ></tr>
      </table>
    </birdz-table-wrapper>
    }
  </div>
</birdz-card>
