<birdz-card [isEmpty]="(hasPerimeter$ | async) !== true" [isLoading]="isLoading > 0">
  <div card-title>{{ 'TAB_MAP' | transloco }}</div>
  <div card-empty>{{ "PLEASE_SELECT_ONE_SINGLE_CONTRACT" | transloco }}</div>
  <div card-body class="body">
    @if (hasNonGeocodedPds) {
    <birdz-alert type="help" icon="info">
      {{ 'MESSAGE_NON_GEOCODED_PDS' | transloco }}
    </birdz-alert>
    } @if (markers) {
    <div class="map">
      <birdz-map
        [searchBar]="true"
        [markers]="markers"
        [markerOnClick]="markerOnClick"
        [useOSM]="true"
        [selectedLayerId]="'mapbox/outdoors-v11'"
        [initOptions]="mapInitOptions"
        [enableCluster]="true"
        [clusterOptions]="clusterOptions"
      />
    </div>

    }
    <div class="legend">
      <div class="legend__item" style="--color: #ffe6dc">
        {{ 'STATE_NON_EQUIPPED' | transloco }}
      </div>
      <div class="legend__item" style="--color: #e0f4d3">{{ 'STATE_WB' | transloco }}</div>
      <div class="legend__item" style="--color: #f7a677">
        {{ 'STATE_NOT_CONNECTED' | transloco }}
      </div>
      <div class="legend__item" style="--color: #7ed17c">{{ 'STATE_FUNCTIONAL' | transloco }}</div>
      <div class="legend__item" style="--color: #95cde8">
        {{ 'STATE_NONFUNCTIONAL_HS' | transloco }}
      </div>
      <div class="legend__item" style="--color: #3a4255">
        {{ 'STATE_NONFUNCTIONAL_NC' | transloco }}
      </div>
    </div>
  </div>
</birdz-card>
