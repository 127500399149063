import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, BirdzService, BirdzTenantService } from '@web/birdz-angular';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, skipWhile, tap } from 'rxjs/operators';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public user$: Observable<User> = this._user$.pipe(
    skipWhile((u) => u == null)
  ) as Observable<User>;
  public needConsent$: Observable<boolean> = this._user$.pipe(
    skipWhile((user) => !user),
    map((user) => user?.need_consent ?? true)
  );

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private birdz: BirdzService,
    private tenantService: BirdzTenantService
  ) {}

  fetchUser(): Observable<User> {
    const url = `${environment.apiUrl}/user`;
    return this.http.get<User>(url).pipe(
      map((userData: any): User => {
        const user: User = new User(userData);
        this.setUser(user);
        this.auth.set(AuthService.AUTHORIZED);
        return user;
      }),
      catchError((error) => {
        const isAuthorized = [401, 403].includes(error?.status)
          ? AuthService.UNAUTHORIZED
          : AuthService.AUTHORIZATION_FAILED;
        this.auth.set(isAuthorized);
        return throwError(() => error);
      })
    );
  }

  setNeedConsent(value: boolean) {
    const user: User | null = this._user$.getValue();
    if (user === null) {
      return;
    }
    user.need_consent = value;
    this.setUser(user);
  }

  getEffectiveTenantId(): string | null {
    const user = this.getUser();
    return (user?.isBirdzAdmin() ? this.tenantService.getTenantId() : user?.getTenant()) ?? null;
  }

  grantConsent() {
    const url = `${environment.apiUrl}/me/grant-consent`;
    return this.http.post(url, null, {});
  }

  contactSupport(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/support`, data);
  }

  clearCache() {
    return this.http.post(`${environment.apiUrl}/me/clear-cache`, {});
  }

  patchSettings(settings: Record<string, any>) {
    return this.http.patch(`${environment.apiUrl}/me/settings`, { settings }).pipe(
      tap((settings: Record<string, any>) => {
        // update settings
        if (settings) {
          this.setSettings(settings);
        }
      })
    );
  }

  setSettings(settings: Record<string, any>) {
    const user: User | null = this.getUser();
    if (user === null) {
      return;
    }
    user.settings = settings;
    this.setUser(user);
  }

  getUser(): User | null {
    return this._user$.getValue();
  }

  setUser(user: User): void {
    this.birdz.configureFor(user);
    this._user$.next(user);
  }

  isBirdzAdmin(): boolean {
    return this.getUser()?.isBirdzAdmin() ?? false;
  }
}
