import { Component, DestroyRef, Input, OnChanges, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoPipe, TranslocoService, translate as _ } from '@jsverse/transloco';
import {
  CardComponent,
  FormatDatePipe,
  FormatNumberPipe,
  FormatterService,
  blueZodiac,
  cantaloupe,
  cornflower,
  pastelGreen,
  rainDrop,
} from '@web/birdz-angular';
import { LegendItemComponent } from 'app/components/legend-item/legend-item.component';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { tap } from 'rxjs';
import { fontFamily } from '../../config/constants.config';
import { RemoteReadingStats } from '../../interfaces/remote-reading-stats.interface';
import { ModalHelpRemoteReadingComponent } from '../modal-help-remote-reading/modal-help-remote-reading.component';

@Component({
  selector: 'app-card-remote-reading',
  templateUrl: './card-remote-reading.component.html',
  styleUrls: ['./card-remote-reading.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    HighchartsChartModule,
    TranslocoPipe,
    FormatNumberPipe,
    FormatDatePipe,
    FormsModule,
    CardComponent,
    MatSlideToggleModule,
    LegendItemComponent,
  ],
})
export class CardRemoteReadingComponent implements OnChanges, OnInit {
  private destroyRef = inject(DestroyRef);
  @Input() stats?: RemoteReadingStats | null = null;
  @Input({ required: true }) hasPerimeter?: boolean;
  @Input({ required: true }) isLoading?: boolean;
  chartOptions?: any;
  isDetailed = false;
  Highcharts = Highcharts;
  updateFlag = false;
  chart?: Highcharts.Chart;

  constructor(
    private transloco: TranslocoService,
    private formatter: FormatterService,
    private dialog: MatDialog
  ) {}

  get total(): number {
    return (
      (this.stats?.devices_connected_nonfunctional ?? 0) +
      (this.stats?.devices_connected_functional ?? 0) +
      (this.stats?.devices_not_connected ?? 0)
    );
  }

  ngOnInit() {
    this.handleLanguageChange();
  }

  ngOnChanges() {
    this.updateChart();
  }
  setChartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
  }
  help() {
    this.dialog.open(ModalHelpRemoteReadingComponent, { maxWidth: '1000px' });
  }

  handleLanguageChange() {
    this.transloco.langChanges$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.updateChart())
      )
      .subscribe();
  }

  resetChart() {
    this.chartOptions = null;
  }

  toggleView() {
    this.updateChart();
  }

  subtitle(count: number, percentage: number | undefined) {
    const DEVICES = _('DEVICES', { count });
    let subtitle = `${this.formatter.number(count ?? 0)} ${DEVICES}`;
    if (percentage != null) {
      subtitle += ` - ${this.formatter.number(percentage, '0.0-1')}%`;
    }
    return subtitle;
  }

  subtitleConnectedFunctional = (point?: Highcharts.Point) => {
    return this.subtitle(this.stats?.devices_connected_functional ?? 0, point?.percentage);
  };

  subtitleConnectedNonFunctional = (point?: Highcharts.Point) => {
    return this.subtitle(this.stats?.devices_connected_nonfunctional ?? 0, point?.percentage);
  };

  subtitleConnectedNonFunctionalNC = (point?: Highcharts.Point) => {
    return this.subtitle(this.stats?.devices_connected_nonfunctional_nc ?? 0, point?.percentage);
  };
  subtitleConnectedNonFunctionalHS = (point?: Highcharts.Point) => {
    return this.subtitle(this.stats?.devices_connected_nonfunctional_hs ?? 0, point?.percentage);
  };
  subtitleNotConnected = (point?: Highcharts.Point) => {
    return this.subtitle(this.stats?.devices_not_connected ?? 0, point?.percentage);
  };

  getData() {
    const connectedFunctional = {
      id: 'connected_functional',
      name: _('CONNECTED_FUNCTIONAL'),
      color: pastelGreen,
      y: this.stats?.devices_connected_functional,
    };
    const connectedNonFunctional = {
      id: 'connected_non_functional',
      name: _('CONNECTED_NONFUNCTIONAL'),
      color: cornflower,
      y: this.stats?.devices_connected_nonfunctional,
    };
    const connectedNonFunctionalNC = {
      id: 'connected_non_functional_nc',
      name: _('CONNECTED_NONFUNCTIONAL_NC'),
      color: blueZodiac,
      y: this.stats?.devices_connected_nonfunctional_nc,
    };
    const connectedNonFunctionalHS = {
      id: 'connected_non_functional_hs',
      name: _('CONNECTED_NONFUNCTIONAL_HS'),
      color: rainDrop,
      y: this.stats?.devices_connected_nonfunctional_hs,
    };
    const notConnected = {
      id: 'not_connected',
      name: _('NOT_CONNECTED'),
      color: cantaloupe,
      y: this.stats?.devices_not_connected,
    };

    return this.isDetailed
      ? [connectedFunctional, connectedNonFunctionalNC, connectedNonFunctionalHS, notConnected]
      : [connectedFunctional, connectedNonFunctional, notConnected];
  }

  updateChart() {
    if (!this.stats) {
      return this.resetChart();
    }
    const data = this.getData();
    this.chartOptions = {
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {
          fontFamily: fontFamily,
        },
      },
      tooltip: {
        pointFormat: `${_('COUNT')}${_('COLON')}{point.y}`,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          colorByPoint: true,
          data: data,
        },
      ],
    };
    this.updateFlag = true;
  }
}
