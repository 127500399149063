import { Routes } from '@angular/router';
import { AuthGuard } from '@web/birdz-angular';
import { PageDashboardComponent } from 'app/components/page-dashboard/page-dashboard.component';
import { PageDetailComponent } from 'app/components/page-details/page-details.component';
import { PageMapComponent } from 'app/components/page-map/page-map.component';

export const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'map', component: PageMapComponent },
      { path: 'dashboard', component: PageDashboardComponent },
      { path: 'details', component: PageDetailComponent },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
];
