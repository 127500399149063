import {
  Component,
  DestroyRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Point } from 'highcharts';
import { fromEvent, tap } from 'rxjs';

@Component({
  selector: 'app-legend-item',
  standalone: true,
  imports: [],
  templateUrl: './legend-item.component.html',
  styleUrl: './legend-item.component.scss',
})
export class LegendItemComponent implements OnInit, OnChanges {
  @Input({ required: true }) id!: string;
  @Input() chart?: Highcharts.Chart;
  @Input() title: string = '';
  @Input() level: number = 0;
  @Input() color?: string;
  @Input() forceDisplay = false;
  @Input() subtitle: (p?: Point) => string = () => '';
  @Input() children: string[] = [];
  @Input() update: boolean = false; // trigger re-render
  private destroyRef = inject(DestroyRef);
  _subtitle: string = '';
  show = false;
  slice: Point | undefined;

  @HostListener('click') onClick() {
    document.dispatchEvent(new CustomEvent('legend-item-clicked'));
  }

  ngOnInit() {
    this.updateOnClick();
  }

  /**
   * When a legend item is clicked, it changes the total and percentage values
   * so other legend-items need to be updated accordingly
   */
  updateOnClick() {
    fromEvent(document, 'legend-item-clicked')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.doUpdate())
      )
      .subscribe();
  }

  ngOnChanges() {
    this.doUpdate();
  }

  isActive() {
    return !!this.slice || this.children.length;
  }

  doUpdate() {
    this.setSlice();
    this.show = !!this.slice || this.forceDisplay;
    this.color = (this.slice?.color as string) ?? this.color;
    this._subtitle = this.subtitle(this.slice);
    this.getTotal();
  }

  getTotal() {
    // @ts-ignore
    return this.chart?.series[0].total ?? Infinity;
  }
  isHidden(): boolean {
    if (this.children.length) {
      const slices = this.children.map((id) => this.getSlice(id));
      return slices.every((slice) => slice?.visible === false);
    }
    return !!this.slice && !this.slice.visible;
  }

  toggle() {
    if (this.slice) {
      this.slice.setVisible(!this.slice.visible);
    }
    if (this.children) {
      const slices = this.children.map((id) => this.getSlice(id));
      const areVisible = slices.some((slice) => slice?.visible === true);
      slices.forEach((slice) => slice?.setVisible(!areVisible));
    }
  }

  setSlice() {
    this.slice = this.getSlice(this.id);
  }
  private getSlice(id: string): Point | undefined {
    return this.chart?.series[0].data.find((s) => s.options.id === id);
  }
}
