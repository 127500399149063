import { Component, DestroyRef, Input, OnChanges, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoPipe, TranslocoService, translate as _ } from '@jsverse/transloco';
import {
  CardComponent,
  FormatDatePipe,
  FormatNumberPipe,
  FormatterService,
  blueZodiac,
  cantaloupe,
  cornflower,
  pastelGreen,
  translateArray,
} from '@web/birdz-angular';
import { LegendItemComponent } from 'app/components/legend-item/legend-item.component';
import { ModalHelpEquippingComponent } from 'app/components/modal-help-equipping/modal-help-equipping.component';
import { fontFamily } from 'app/config/constants.config';
import { EquippingStats } from 'app/interfaces/equipping-stats.interface';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { tap } from 'rxjs';

@Component({
  selector: 'app-card-equipping',
  templateUrl: './card-equipping.component.html',
  styleUrls: ['./card-equipping.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    HighchartsChartModule,
    TranslocoPipe,
    FormatNumberPipe,
    FormsModule,
    FormatDatePipe,
    CardComponent,
    MatSlideToggleModule,
    LegendItemComponent,
  ],
})
export class CardEquippingComponent implements OnChanges, OnInit {
  private destroyRef = inject(DestroyRef);
  isDetailed = false;
  @Input() stats?: EquippingStats | null = null;
  @Input({ required: true }) hasPerimeter?: boolean;
  @Input({ required: true }) isLoading?: boolean;
  chartOptions?: any;
  chart?: Highcharts.Chart;
  Highcharts = Highcharts;
  updateFlag = false;
  cornflower = cornflower;

  constructor(
    private transloco: TranslocoService,
    private formatter: FormatterService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.handleLanguageChange();
  }

  ngOnChanges() {
    this.updateChart();
  }
  subtitleEquipped = (point?: Highcharts.Point) => {
    return this.subtitle(this.stats?.pds_equipped ?? 0, point?.percentage);
  };
  subtitleNonEquipped = (point?: Highcharts.Point) => {
    return this.subtitle(
      (this.stats?.pds_total ?? 0) - (this.stats?.pds_equipped ?? 0),
      point?.percentage
    );
  };
  subtitleEquippedFN = (point?: Highcharts.Point) => {
    return this.subtitle(this.stats?.pds_equipped_fix_network ?? 0, point?.percentage);
  };
  subtitleEquippedWB = (point?: Highcharts.Point) => {
    return this.subtitle(this.stats?.pds_equipped_walk_by ?? 0, point?.percentage);
  };

  handleLanguageChange() {
    this.transloco.langChanges$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.updateChart())
      )
      .subscribe();
  }

  toggleView() {
    this.updateChart();
  }

  resetChart() {
    this.chartOptions = null;
  }

  subtitle(count: number, percentage: number | undefined) {
    const METERS = _('METERS', { count });
    let subtitle = `${this.formatter.number(count ?? 0)} ${METERS}`;
    if (percentage != null) {
      subtitle += ` - ${this.formatter.number(percentage, '0.0-1')}%`;
    }
    return subtitle;
  }

  help() {
    this.dialog.open(ModalHelpEquippingComponent, { maxWidth: '900px' });
  }

  setChartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
  }

  getData() {
    const nonEquippedMeters = {
      id: 'non_equipped_meters',
      name: _('NON_EQUIPPED_METERS'),
      color: cantaloupe,
      y: (this.stats?.pds_total ?? 0) - (this.stats?.pds_equipped ?? 0),
    };
    const equippedMeters = {
      id: 'equipped_meters',
      name: _('EQUIPPED_METERS'),
      color: cornflower,
      y: this.stats?.pds_equipped,
      data: this.stats ?? {},
    };
    const equippedMetersFN = {
      id: 'equipped_meters_fix_network',
      name: _('EQUIPPED_METERS_FIX_NETWORK'),
      color: pastelGreen,
      y: this.stats?.pds_equipped_fix_network,
      data: this.stats ?? {},
    };
    const equippedMetersWB = {
      id: 'equipped_meters_walk_by',
      name: _('EQUIPPED_METERS_WALK_BY'),
      color: blueZodiac,
      y: this.stats?.pds_equipped_walk_by,
      data: this.stats ?? {},
    };

    return this.isDetailed
      ? [nonEquippedMeters, equippedMetersFN, equippedMetersWB]
      : [nonEquippedMeters, equippedMeters];
  }

  updateChart() {
    if (!this.stats) {
      return this.resetChart();
    }

    const data = this.getData();
    const __ = translateArray(['COUNT', 'COLON']);
    this.chartOptions = {
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {
          fontFamily: fontFamily,
        },
      },
      tooltip: {
        pointFormat: `${__.COUNT}${__.COLON}{point.y}`,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          colorByPoint: true,
          data: data,
        },
      ],
    };
    this.updateFlag = true;
  }
}
