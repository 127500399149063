@if(show) {
<div
  class="legend"
  (click)="toggle()"
  [class.legend--active]="isActive()"
  [class.legend--hidden]="isHidden()"
  [style.--level]="level"
>
  <div class="icon" [style.--color]="color"></div>
  <div class="title">{{ title }}</div>
  <div class="subtitle">{{ _subtitle }}</div>
</div>
}
