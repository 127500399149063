import { Component } from '@angular/core';
import { CardMapComponent } from 'app/components/card-map/card-map.component';

@Component({
  selector: 'app-page-map',
  standalone: true,
  imports: [CardMapComponent],
  templateUrl: './page-map.component.html',
  styleUrl: './page-map.component.scss',
})
export class PageMapComponent {}
