<birdz-modal>
  <div modal-title>{{ 'HELP_RR_1' | transloco }}</div>
  <div modal-body class="body">
    <dl class="dl">
      <dt>{{ 'HELP_RR_2' | transloco }}</dt>
      <dd>{{ 'HELP_RR_3' | transloco }}</dd>

      <dt>{{ 'HELP_RR_4' | transloco }}</dt>
      <dd>{{ 'HELP_RR_5' | transloco }}</dd>

      <dt>{{ 'HELP_RR_4A' | transloco }}</dt>
      <dd>{{ 'HELP_RR_5A' | transloco }}</dd>

      <dt>{{ 'HELP_RR_4B' | transloco }}</dt>
      <dd>{{ 'HELP_RR_5B' | transloco }}</dd>

      <dt>{{ 'HELP_RR_6' | transloco }}</dt>
      <dd>{{ 'HELP_RR_7' | transloco }}</dd>
      <dt>{{ 'HELP_RR_8' | transloco }}</dt>
      <dd>{{ 'HELP_RR_9' | transloco }}</dd>
      <dt>{{ 'HELP_RR_10' | transloco }}</dt>
      <dd>{{ 'HELP_RR_11' | transloco }}</dd>
    </dl>
    <p>{{ 'HELP_RR_12' | transloco }}</p>
  </div>
</birdz-modal>
