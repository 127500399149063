import { Component } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { ModalComponent } from '@web/birdz-angular';

@Component({
  selector: 'app-modal-help-index-reporting',
  templateUrl: './modal-help-index-reporting.component.html',
  styleUrls: ['./modal-help-index-reporting.component.scss'],
  standalone: true,
  imports: [TranslocoPipe, ModalComponent],
})
export class ModalHelpIndexReportingComponent {}
