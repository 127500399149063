<birdz-auth>
  <!-- header -->
  <birdz-header
    title="{{ 'APP_NAME' | transloco }}"
    [isLoading]="(isLoading$ | async) || false"
    [profile]="(profile$ | async) ?? undefined"
    [userSettings]="settings$ | async"
    (userSettingsChange)="onUserSettingsChange($event)"
    [contactCallback]="contactCallback"
    [logoRedirectionUrl]="logoRedirectionUrl"
  >
    <button mat-menu-item (click)="openGDPRModal()">
      <span>{{ "GDPR" | transloco }}</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <span>{{ "LOGOUT" | transloco }}</span>
    </button>
  </birdz-header>

  <birdz-layout *ifAuthorized>
    <div layout-left>
      <birdz-drawer>
        <app-filter></app-filter>
      </birdz-drawer>
    </div>
    <div layout-right>
      <!-- tabs -->
      <nav mat-tab-nav-bar class="navbar" [tabPanel]="tabPanel" [mat-stretch-tabs]="false">
        @for(tab of tabs; track tab.path) {
        <a
          mat-tab-link
          [routerLink]="tab.path"
          queryParamsHandling="merge"
          routerLinkActive
          #rla="routerLinkActive"
          class="tab"
          [active]="rla.isActive"
        >
          {{ tab.label | transloco }}
        </a>
        }
      </nav>
      <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

      <!-- router outlet -->
      <div class="content">
        @if(needsTenant$ | async) {
        <birdz-card-tenant></birdz-card-tenant>
        } @else {
        <router-outlet></router-outlet>
        }
      </div>
    </div>
  </birdz-layout>
</birdz-auth>
